.TechnologyHeading {
  padding: 60px 0 40px 0;
  background-image: url('../../../../assets/developmentTools/web_background.png');
  background-repeat: no-repeat no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
}

.TechnologyHeading .TechnologyHeading_banner_img img {
  max-height: 500px;
  width: 100%;
}

.TechnologyHeading .hero_container_sub {
  font-size: 17px;
}

.TechnologyHeading .ant-row {
  align-items: center;
}
