.additional-tech {
  padding-bottom: 100px;
}

.technology-page .additional-tech {
  background: #c5840e0a;
}

/* .technology-page .additional-tech .odd_and_even_seperator:nth-child(even) {
  flex-direction: row-reverse !important;
} */

.additional_description_wrapper:nth-child(even) {
  flex-direction: row-reverse !important;
}
