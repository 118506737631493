#PrivacyPolicy,
#TermsConditions {
  padding: 40px 0 170px 0;
}

#PrivacyPolicy h1,
#TermsConditions h1 {
  color: var(--text-color-one) !important;
}

#PrivacyPolicy h2,
#TermsConditions h2 {
  color: var(--text-color-two) !important;
}

#PrivacyPolicy .PrivacyPolicy-wrapper,
#TermsConditions .PrivacyPolicy-wrapper {
  border-radius: 20px;
  color: var(--text-color-one);
  text-align: justify;
  padding: 20px;
}
