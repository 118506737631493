.marquee_slider_about_us img {
  margin: 20px 20px;
  /* border-radius: 50%; */
  height: 132px;
  width: 132px;
}
.marquee_slider_about_us .marquee {
  position: relative;
}

.marquee_slider_about_us .marquee:after {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}

/* .marquee_slider_about_us .marquee {
  display: grid !important;
  grid-template-columns: auto auto auto auto auto !important;
} */
