.lets_connect_footer_banner {
  /* position: absolute;
  z-index: 6; */
}

.lets_connect_footer_banner .lets_connect_footer_banner_wrapper {
  text-align: center;
  background: #001933;
  padding: 30px;
  border-radius: 6px;
}

.lets_connect_footer_banner .lets_connect_footer_banner_heading {
  font-size: 36px;
  color: #fff;
}

.lets_connect_footer_banner .lets_connect_footer_banner_subheading {
  font-size: 18px;
  color: rgb(212, 209, 209);
}
