.IndividualCard .IndividualCard_wrapper {
  display: inline-block;
}

.IndividualCard .IndividualCard_wrapper .IndividualCard_img {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}

.IndividualCard .IndividualCard_wrapper .IndividualCard_img:before {
  display: inline-block;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
}

.IndividualCard .IndividualCard_wrapper .IndividualCard_img img {
  height: 345px;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.IndividualCard .IndividualCard_wrapper .IndividualCard_text_field {
  position: absolute;
  bottom: 10px;
  z-index: 3;
  /* width: 86%; */
  display: inline-block;
  color: #fff;
}

.IndividualCard
  .IndividualCard_wrapper
  .IndividualCard_text_field
  .IndividualCard_name {
  font-size: 18px;
  font-weight: bold;
  display: table-header-group;
  background: rgba(0, 0, 0, 0.5);
}

.IndividualCard
  .IndividualCard_wrapper
  .IndividualCard_text_field
  .IndividualCard_info {
  font-size: 16px;
  display: table-cell;
  background: rgba(0, 0, 0, 0.5);
}

/* .IndividualCard .IndividualCard_wrapper .overlay .text {
  display: inline-block;
} */

.IndividualCard .IndividualCard_wrapper .overlay {
  position: absolute;
  top: 0;
  border-radius: 12px;
  height: 100%;
  width: 345px;
  opacity: 0;
  z-index: 5;
  transition: 0.5s ease;
  background-color: var(--primary-color);
}

@media screen and (min-width: 768px) {
  .IndividualCard .IndividualCard_wrapper .overlay {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .IndividualCard .overlay_IndividualCard_name {
    text-align: center;
    display: block !important;
  }
}

.IndividualCard .IndividualCard_wrapper:hover .overlay {
  opacity: 1;
}

.IndividualCard .IndividualCard_wrapper .linked_in_icon {
  position: absolute;
  right: 40px;
  top: 1px;
  z-index: 15;
}

.IndividualCard .IndividualCard_wrapper .linked_in_icon svg {
  border-top-right-radius: 10px;
  padding: 7px;
  color: #fff;
  background: #0a66c2;
}

.IndividualCard .IndividualCard_wrapper .overlay_wrapper {
  padding: 17px;
}

.IndividualCard .IndividualCard_wrapper .overlay_wrapper img {
  height: 96px;
  width: 96px;
  border: 2px solid #fff;
  border-radius: 50%;
  object-fit: cover;
  margin: 30px 0 15px 0;
}

.overlay_IndividualCard_text_field {
  color: #fff;
}

.overlay_IndividualCard_name {
  font-size: 18px;
  font-weight: bold;
  display: table-header-group;
}

.overlay_IndividualCard_info {
  font-size: 16px;
  margin: 10px 0;
}

.overlay_IndividualCard_description {
  font-size: 16px;
}
