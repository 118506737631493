.TechnologyStack_component {
  padding: 60px 0 180px 0;
  background: url('../../../../assets/Services/technology_background.jpg')
    no-repeat center;

  width: 100%;
  background-size: cover;
  background-position: center;
}

.TechnologyStack_component .TechnologyStack_heading {
  font-size: 24px;
  color: #ff8c47;
  font-weight: 600;
}

.TechnologyStack_component .TechnologyStack_wrapper {
  text-align: center;
}

.TechnologyStack_component .TechnologyStack_subheading {
  font-size: 32px;
  color: #fff;
  font-weight: 600;
  margin: 15px 0;
}

.TechnologyStack_component .development_tools_img img {
  height: 98px;
  width: 98px;
}

.TechnologyStack_component .development_tools_img {
  padding: 20px;
  display: inline-flex;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.23);
}

.TechnologyStack_component .development_tools_img {
  margin-top: 20px;
}
