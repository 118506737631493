#Service_Hero_container {
  background: url('../../../../assets/Services/Service_background.png')
    no-repeat center;
  width: 100%;
  background-size: cover;
  background-position: center;
}

#Service_Hero_container .Service_Hero_container_email_form {
  padding: 5px 9px;
  border: 1.5px solid #969191;
  box-sizing: border-box;
  border-radius: 8px;
  display: block;
  background: #ffffff;
  max-width: 300px;
  margin-top: 30px;
}

@media screen and (max-width: 512px) {
  #Service_Hero_container #hero_svg {
    margin-top: -40%;
  }
}

#Service_Hero_container .Service_Hero_container_email_form .ant-btn {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  border-radius: 5px;
}
#Service_Hero_container .Service_Hero_container_email_form .ant-input {
  border: none !important;
}
