#AboutusHero .AboutusHero_wrapper {
  text-align: center;
  background: linear-gradient(0deg, rgba(0, 25, 51, 0.5), rgba(0, 25, 51, 0.5)),
    url('../../../../assets/aboutus/aboutbg.jpg');
  background-repeat: no-repeat no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
}
#AboutusHero .AboutusHero_wrapper_background {
  position: absolute;
}

#AboutusHero .AboutusHero_wrapper .hero_container_heading {
  width: 70%;
  margin: 0 auto;
}

#AboutusHero .AboutusHero_wrapper .hero_container_sub-heading {
  width: 50%;
  margin: 0 auto;
}

@media screen and (max-width: 776px) {
  #AboutusHero .AboutusHero_wrapper .hero_container_heading {
    width: 95%;
    margin: 0 auto;
  }

  #AboutusHero .AboutusHero_wrapper .hero_container_sub-heading {
    width: 95%;
    margin: 0 auto;
  }

  #AboutusHero .AboutusHero_wrapper_text_content {
    padding: 95px 0 !important;
  }
}

#AboutusHero .AboutusHero_wrapper_background img {
  height: 100%;
  width: 100%;
}

#AboutusHero .AboutusHero_wrapper_text_content {
  padding: 130px 0;
}

#AboutusHero .AboutusHero_wrapper_text_content h1,
#AboutusHero .AboutusHero_wrapper_text_content h5 {
  color: #fff;
}

#AboutusHero .scroll_down_btn {
  text-align: center;
  color: #fff;
  margin-top: -68px;
}

#AboutusHero .scroll_down_btn svg {
  transform: rotate(90deg);
}

#AboutusHero .scroll_down_btn {
  -webkit-animation: scroll_down_animation 1.2s infinite alternate;
  animation: scroll_down_animation 1.2s infinite alternate;
}

@-webkit-keyframes scroll_down_animation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes scroll_down_animation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
