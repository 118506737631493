@media only screen and (min-width: 575px) {
  .our_service_card {
    padding: 0 20px;
  }
}

.our_service_card:hover .our_service_card_wrapper {
  border-color: var(--primary-color);
}

.our_service_card .our_service_icon img {
  height: 65px;
  width: 65px;
  padding: 12px;
}

.our_service_card .our_service_icon {
  margin-right: 10px;

  vertical-align: middle;
  display: inline-block;
  text-align: center;
  transition: 0.4s;
}

.our_service_card .our_service_title p {
  font-size: 18px;
  font-weight: bolder;
  margin: 13px 0;
}

.our_service_card .our_service_card_wrapper {
  text-align: center;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px 10px;
  margin-bottom: 30px;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.our_service_card .our_service_description {
  padding: 0 15px;
}

.our_service_card .bottom_line_draw {
  width: 20%;
  height: 0px;
  background: #ff6c14;
  border: 0.1px solid #ff6c14;
  flex: none;
  order: 3;
  flex-grow: 0;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.5s;
}

.our_service_card .our_service_card_wrapper:hover .bottom_line_draw {
  width: 90%;
}

.our_service_card .our_service_card_wrapper:hover {
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.15);
}
