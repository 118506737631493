#VacancyResult {
  padding: 80px 0 300px 0;
}

#VacancyResult .VacancyResult_wrapper .ant-form {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

#VacancyResult .VacancyResult_wrapper .form-heading {
  text-align: center;
}

#VacancyResult .VacancyResult_wrapper .form-heading p {
  color: var(--primary-color);
}

#VacancyResult .VacancyResult_wrapper .logo img {
  height: 85px;
  width: 200px;
}

#VacancyResult .VacancyResult_wrapper .logo {
  text-align: center;
  margin-bottom: 50px;
}
#VacancyResult .result-message {
  text-align: center;
}
#VacancyResult .result-message p {
  font-size: 18px;
}
