.Jobscard {
  margin: 10px;
}

.Jobscard .Jobscard_wrapper {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 20px;
}

.Jobscard .Jobscard_heading {
  font-weight: 700;
  font-size: 24px;
}

.Jobscard .Jobscard_time,
.Jobscard_location,
.Jobscard_more_details {
  font-weight: 400;
  font-size: 18px;
  margin: 5px 0;
}

.Jobscard .Jobscard_wrapper a {
  color: var(--primary-color);
}
