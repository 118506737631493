.TestimonialCard {
  padding: 10px;
}

.TestimonialCard .TestimonialCard_wrapper {
  text-align: center;
  border: 2px solid rgba(0, 0, 0, 0.881);
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.TestimonialCard .left_quote,
.TestimonialCard .right_quote {
  position: absolute;
}

.TestimonialCard .left_quote img,
.TestimonialCard .right_quote img {
  height: 30px;
  width: 23px;
}
.TestimonialCard .left_quote {
  left: -12px;
  top: 50%;
}

.TestimonialCard .right_quote {
  right: -12px;
  top: 50%;
}

/* .triangle-left-bottom {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 35px solid #ff750030;
  border-right: 70px solid transparent;
} */

.testimonial_card_shape {
  margin-top: -98px;
  padding-bottom: 30px;
}

.TestimonialCard .TestimonialCard_img img {
  height: 120px;
  width: 120px;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  margin: 35px 0 15px 0;
}

.TestimonialCard .TestimonialCard_title {
  font-weight: 700;
  font-size: 24px;
  color: #ff6c14;
}

.TestimonialCard .TestimonialCard_text {
  text-align: center;
  margin: 15px 0;
  font-size: 18px;
}

/* .Testimonail_shape {
  position: absolute;
  top: 160px;
  height: 100%;
  width: 100%;
} */

.Testimonail_shape img {
  /* height: 100%; */
  width: 100%;
}
