#Blog {
  margin-top: 10px;
  margin-bottom: 160px;
}

@media screen and (min-width: 992px) {
  #Blog {
    margin-top: 50px;
    margin-bottom: 160px;
  }

  #Blog_page .heading-text h1 {
    margin-bottom: -20px !important;
  }
}

#Blog .service_rows_and_column_illustration {
  text-align: center;
}

#Blog .loading-spinner {
  text-align: center;
  height: 100vh;
}

#Blog .loading-spinner svg {
  font-size: 35px;
  margin-top: 20%;
}

#Blog_page .heading-text {
  text-align: center;
  margin: 30px 0;
}

#Blog_page .heading-text h1 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: -50px;
}

#Blog_page .blog_page_banner_card img {
  height: 300px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  margin-top: 20px;
}

#Blog_page .blog_page_banner_card a h1 {
  color: var(--primary-color) !important;
}

@media screen and (min-width: 992px) {
  #Blog_page .blog_page_banner_card a h1,
  #Blog_page .blog_page_banner_card a h4 {
    width: 95%;
  }
}

@media screen and (max-width: 992px) {
  #Blog_page .blog_page_banner_card_wrapper .ant-row {
    flex-direction: column-reverse;
  }

  #Blog_page .blog_page_banner_card img {
    margin-bottom: 20px;
  }
}
