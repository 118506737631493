.CounterContainer {
  padding: 20px 0 40px 0;
  text-align: center;
}

.CounterContainer_wrapper {
  background: #ff6c14;
  border-radius: 12px;
  padding: 50px 0;
}

.CounterContainer_wrapper .couner_number {
  color: #fff;
  font-size: 28px;
  margin-top: 20px;
}

.CounterContainer_wrapper .couner_text {
  color: #fff;
  font-size: 28px;
}
