.why_choose_us_component {
  background: #fff9f5;
}

.landing_page_component_content .bg_shape {
  width: 100%;
  height: auto;
}

.landing_page_component_content .bg_shape img {
  width: 100%;

  /* filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%); */
}

/* Landing Page css */
.landing_page_component {
  padding: 40px 0;
}

.landing_page_component_title {
  font-weight: 800;
  font-size: 24px;
  color: var(--text-color-one);
  line-height: 33px;
  letter-spacing: 0.05em;
}

.landing_page_component_content {
  margin-top: 25px;
}

/* ending of Landing Page css */

/* .landing_page_component_content .macbook_pro {
  width: 100%;
  height: auto;
  position: absolute;
  left: 5%;
  transform: translateY(50%);
} */

.landing_page_component_content .macbook_pro {
  width: 100%;
  height: auto;
  position: absolute;
  left: 8%;
  transform: translateY(37%);
}

.landing_page_component_content .macbook_pro img {
  width: 95%;
}

.landing_page_component_content .inside_macbook {
  width: 78.9%;
  height: auto;
  position: absolute;
  left: 16.5%;
  z-index: 6;
  transform: translateY(44%);
}

.landing_page_component_content .inside_macbook img {
  width: 99%;
  border-radius: 5px;
}

.why_choose_us_sub_heading {
  font-weight: bold;
  font-size: 21px;
  margin-top: 20px;
}

.why_choose_us_sub_text {
  font-size: 18px;
  text-align: justify;
  margin-top: 20px;
  line-height: 30px;
}
