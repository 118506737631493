body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



svg#freepik_stories-developer-activity:not(.animated) .animable {
  opacity: 0;
}

svg#freepik_stories-developer-activity.animated #freepik--background-complete--inject-2 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight;
  animation-delay: 0s;
}

svg#freepik_stories-developer-activity.animated #freepik--Plant--inject-2 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn;
  animation-delay: 0s;
}

svg#freepik_stories-developer-activity.animated #freepik--binary-numbers--inject-2 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight;
  animation-delay: 0s;
}

svg#freepik_stories-developer-activity.animated #freepik--speech-bubble--inject-2 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedLeft;
  animation-delay: 0s;
}

svg#freepik_stories-developer-activity.animated #freepik--Screens--inject-2 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown;
  animation-delay: 0s;
}

svg#freepik_stories-developer-activity.animated #freepik--Device--inject-2 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut;
  animation-delay: 0s;
}

svg#freepik_stories-developer-activity.animated #freepik--Character--inject-2 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
  animation-delay: 0s;
}

svg#freepik_stories-developer-activity.animated #freepik--Floor--inject-2 {
  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
  animation-delay: 0s;
}

@keyframes slideRight {
  0% {
      opacity: 0;
      transform: translateX(30px);
  }

  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes zoomIn {
  0% {
      opacity: 0;
      transform: scale(0.5);
  }

  100% {
      opacity: 1;
      transform: scale(1);
  }
}

@keyframes lightSpeedLeft {
  from {
      transform: translate3d(-50%, 0, 0) skewX(20deg);
      opacity: 0;
  }

  60% {
      transform: skewX(-10deg);
      opacity: 1;
  }

  80% {
      transform: skewX(2deg);
  }

  to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
  }
}

@keyframes slideDown {
  0% {
      opacity: 0;
      transform: translateY(-30px);
  }

  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes zoomOut {
  0% {
      opacity: 0;
      transform: scale(1.5);
  }

  100% {
      opacity: 1;
      transform: scale(1);
  }
}

@keyframes slideUp {
  0% {
      opacity: 0;
      transform: translateY(30px);
  }

  100% {
      opacity: 1;
      transform: inherit;
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}