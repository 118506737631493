.CareerLink {
  padding: 40px 0;
}

.CareerLink_wrapper {
  text-align: center;

  background: url('../../../../assets/aboutus/career-bg.png');
  background-repeat: no-repeat no-repeat !important;
  background-position: left;
  background-size: contain !important;
}

.CareerLink_heading {
  font-weight: 700;
  font-size: 26px;
}

.CareerLink_sub_heading {
  font-weight: 400;
  font-size: 26px;
}
