#Testimonial {
  z-index: 5;
  position: relative;
  padding: 60px 0 150px 0;
  background: #ffffff;
}

#Testimonial .Testimonial_wrapper .swiper-horizontal {
  margin-top: 30px;
  padding-top: 70px;
  padding-bottom: 40px;
}

#Testimonial .Testimonial_wrapper .swiper-pagination {
  margin-top: 20px !important;
}

#Testimonial .Testimonial_wrapper .Testimonial_heading {
  font-size: 36px;
  text-align: center;
  font-weight: 600;
  color: #fff;
}

/* #Testimonial .Testimonial_wrapper .swiper-slide-duplicate {
  margin-right: 50.5px !important;
} */

#Testimonial
  .Testimonial_wrapper
  .swiper-horizontal
  > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -8px;
}

/* #Testimonial .Testimonial_wrapper .swiper-horizontal .container {
  margin-left: 15px !important;
  margin-right: 15px !important;
} */

#Testimonial .Testimonial_wrapper .swiper-pagination-bullet-active {
  background: var(--primary-color);
}

@media screen and (max-width: 768px) {
  .landing_page_component_description {
    width: 95% !important;
  }
}
.landing_page_component_description {
  text-align: center;
}

.landing_page_component_title {
  text-align: center;
}

.Testimonial_wrapper .ant-divider {
  min-width: 12%;
  width: 12%;
  margin: 5px 0;
  margin-left: auto;
  margin-right: auto;
  border-top: 2px solid var(--primary-color);
}

.Testimonial_wrapper .swiper-pagination-bullet {
  width: 40px !important;
  border-radius: 6px;
  height: 4px;
}
