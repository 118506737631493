#TermsAndCondition .TermsAndCondition-wrapper {
  padding: 40px 0 170px 0;
}

#TermsAndCondition h1 {
  color: var(--text-color-one) !important;
}

#TermsAndCondition h2 {
  color: var(--text-color-two) !important;
}

#TermsAndCondition .TermsAndCondition-wrapper {
  color: var(--text-color-one);
  text-align: justify;
}
