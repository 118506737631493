#BlogPage {
  background-color: #f5f5f5;
  /* height: 100vh; */
}

#BlogPage .BlogPage_wrapper {
  padding: 40px 0 150px 0;
}

#BlogPage .BlogPage_heading {
  font-size: 28px;
  font-weight: 600;
  padding: 0px 10px;
}
#BlogPage .BlogPage_heading_text {
  font-size: 14px;
  margin: 20px 0;
  padding: 0px 10px;
}

#BlogPage .BlogPage_image img {
  height: 450px;
  width: 100%;
  object-fit: contain;
}

#BlogPage .BlogPage_image {
  font-size: 18px;
  text-align: justify;
  padding: 0px 10px;
}

@media screen and (max-width: 678px) {
  #BlogPage .BlogPage_image img {
    height: unset;
  }
}

@media screen and (min-width: 768px) {
  #BlogPage .BlogPage_image img {
    width: 95%;
  }
  #BlogPage .BlogPage_image {
    width: 95%;
  }
}

#BlogPage .sidebar-blogs {
  display: flex;
  margin: 0px 0 50px 0;
  padding: 0px 10px;
}

#BlogPage .blog-sidebar-heading {
  font-size: 18px;
  font-weight: 600;
  padding: 0 10px;
}

#BlogPage .blog_content {
  margin: 20px 0;
}
