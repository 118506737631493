.service_rows_and_column_text {
  margin: 50px 0;
  /* text-align: justify; */
}

.odd_and_even_seperator:nth-child(even) {
  flex-direction: row-reverse;
}

.odd_and_even_seperator {
  margin: 15px 0;
  align-items: center;
}

.odd_and_even_seperator:last-of-type .ant-divider-horizontal {
  display: none;
}
