#ContactForm {
  padding: 60px 0;
}

#ContactForm .ContactForm_wrapper .ant-form {
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 10%);
}

#ContactForm .contacts_contact_card .contacts_contact_card_wrapper {
  border-radius: 16px;
}

#ContactForm .contacts_contact_card .contact_text_heading {
  font-weight: 700;
  font-size: 24px;
  margin-top: 25px;
}

#ContactForm .contacts_contact_card .contact_text_subheading {
  font-size: 18px;
  color: #3a444f;
}

#ContactForm iframe {
  border-radius: 16px;
  height: 300px !important;
}

#ContactForm .have_a_project_heading {
  font-size: 48px;
  color: #ff6c14;
  font-weight: 800;
}

#ContactForm .have_a_project_subheading {
  font-size: 24px;
}

#ContactForm .ContactForm_wrapper .ant-row .ant-form-item-control,
#ContactForm .ContactForm_wrapper .ant-row .ant-form-item-label {
  margin: 0 7px;
}

@media screen and (max-width: 766px) {
  #ContactForm .ContactForm_wrapper .career_text {
    box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 10%);
    padding: 20px 10px;
    margin: 20px 0;
    font-weight: 700;
  }
}
