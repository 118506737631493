.MissionAndVison {
  padding: 50px 0;
}

.MissionAndVison .MissionAndVison_header {
  color: #02528d;
  font-weight: 700;
  font-size: 26px;
  margin: 10px 0;
}

.MissionAndVison .MissionAndVison_text {
  text-align: justify;
  font-size: 18px;
}

.MissionAndVison .MissionAndVison_image {
  text-align: center;
  margin: 20px 0;
}

.MissionAndVison .MissionAndVison_image .who-we-are-img img {
  /* width: 390px; */
  height: 390px;
  width: 380px;
}

.who_we_are:nth-child(even) {
  flex-direction: row-reverse;
}

.Vison_image img {
  height: 330px;
  width: 70%;
}

@media screen and (max-width: 768px) {
  .MissionAndVison .mission-row {
    flex-direction: column-reverse !important;
  }
}
