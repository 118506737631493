.CareerHero {
  padding: 80px 0;
  background-image: url('../../../../assets/Career/career-background.png');
  background-repeat: no-repeat no-repeat !important;
  background-position: center center !important;
  /* background-size: cover !important; */
}

.CareerHero .CareerHero_banner_img img {
  max-height: 400px;
  max-width: 400px;
  width: 100%;
}

.CareerHero .CareerHero_banner_img {
  text-align: center;
  margin-top: 30px;
}

.CareerHero .ant-row {
  align-items: center;
}

.CareerHero .hero_container_buttons {
  margin-top: 30px;
}

.CareerHero_wrapper {
}
