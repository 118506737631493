.our_services_component {
  z-index: 5;
  position: relative;
}

.landing_page_component_content a {
  color: var(--text-color-one);
  transition: 0.2s;
}
.landing_page_component_title {
  font-size: 36px !important;
}

.landing_page_component_content a:hover {
  color: var(--primary-color);
}

.landing_page_component_content {
  margin-top: 70px !important;
}

.our_services_component {
  padding: 40px 0;
}

#tsparticles_our_services_component {
  position: absolute;
  width: 100%;
}

.landing_page_component_description {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .landing_page_component_description {
    width: 95% !important;
  }
}

.landing_page_component_wrapper {
  text-align: center;
}

.landing_page_component_wrapper .ant-divider {
  min-width: 10%;
  width: 10%;
  margin: 5px 0;
  margin-left: auto;
  margin-right: auto;
  border-top: 2px solid var(--primary-color);
}
