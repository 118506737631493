@media only screen and (min-width: 992px) {
  #Mobile_Navbar {
    display: none;
  }
}

#Mobile_Navbar .mobile_nav_wrapper {
  padding-top: 15px;
  padding-bottom: 15px;
}

#Mobile_Navbar .mobile_nav_contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#Mobile_Navbar .mobile_nav_contents .logo img {
  height: 40px;
  width: 115px;
}

#Mobile_Navbar .ant-drawer-body ul {
  margin: 0 !important;
  padding: 0 !important;
}

#Mobile_Navbar .ant-drawer-body .ant-menu-inline {
  width: 100% !important;
}

.mobile_drawer .ant-drawer-body {
  padding: 0 !important;
}
/* .mobile_drawer .ant-drawer-title {
  text-align: center;
} */

.mobile_drawer .ant-drawer-title img {
  height: 40px;
  width: 96px;
}

.mobile_drawer .ant-drawer-body a {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  line-height: 37px;
}

.ant-drawer-left.ant-drawer-open {
  width: 70%;
}

.mobile_nav_menu_icon .menu_icon,
.mobile_nav_menu_icon .close_menu_icon {
  transition: 1s;
}

.show {
  display: block;
}
.hide {
  display: none;
}

/* antd ----------- */

.ant-menu-item-selected {
  color: var(--primary-color);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid var(--primary-color);
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: var(--primary-color);
}

.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: var(--primary-color);
}

#Mobile_Navbar {
  box-shadow: 6px 0 16px -8px rgb(0 0 0 / 8%), 9px 0 28px 0 rgb(0 0 0 / 5%),
    12px 0 48px 16px rgb(0 0 0 / 3%);
}
