.OurTeamSlider {
  background: #fafafa;
  padding: 60px 0;
}

.OurTeamSlider_wrapper .swiper-wrapper {
  margin: 30px 15px;
}

.OurTeamSlider .OurTeamSlider_heading {
  font-size: 32px;
  text-align: center;
}

.OurTeamSlider .OurTeamSlider_subheading {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  margin: 15px 0 20px 0;
}

/* .OurTeamSlider_wrapper
  .swiper-slide-active
  .IndividualCard
  .IndividualCard_wrapper
  .IndividualCard_img
  img {
  transform: scale(1.2);
  width: 100%;
  border-radius: 12px;
  transition: 0.7s;
} */

.OurTeamSlider_wrapper
  .swiper-slide-active
  .IndividualCard
  .IndividualCard_wrapper
  .IndividualCard_img
  img {
  /* transform: scale(1.2); */
  height: 400px;
  width: 100%;
  border-radius: 12px;
  transition: 0.7s;
}

.OurTeamSlider_wrapper
  .swiper-slide-active
  .IndividualCard_wrapper
  .linked_in_icon {
  position: absolute;
  right: 1px !important;
  top: 1px;
  z-index: 15;
}

.OurTeamSlider_wrapper .swiper-slide-active .IndividualCard_wrapper .overlay {
  width: 100%;
}

.OurTeamSlider_wrapper .swiper-button-prev,
.OurTeamSlider_wrapper .swiper-button-next {
  top: 2%;
}

.OurTeamSlider_wrapper .swiper-button-disabled {
  display: none;
}

.OurTeamSlider_wrapper .swiper-button-next {
  background: url('../../../../../assets/Services/next_arrow.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.OurTeamSlider_wrapper .swiper-button-next::after {
  display: none;
}

.OurTeamSlider_wrapper .swiper-button-prev {
  background: url('../../../../../assets//Services/prev_arrow.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.OurTeamSlider_wrapper .swiper-button-prev::after {
  display: none;
}

.OurTeamSlider_wrapper .swiper-horizontal {
  padding: 50px 20px;
}

@media screen and (max-width: 775px) {
  .OurTeamSlider_wrapper {
    display: none;
  }

  .IndividualCard .IndividualCard_wrapper .IndividualCard_img:before {
    box-shadow: none;
  }
  .swiper-3d .swiper-slide-shadow {
    background: none !important;
  }
  .OurTeamSlider_mobile {
    text-align: center;
  }
}

@media screen and (min-width: 776px) {
  .OurTeamSlider_mobile {
    display: none !important;
  }
}
