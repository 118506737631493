.job_details_wrapper {
  padding-bottom: 200px;
}

#JobDetails .job_detais_heading .job_detais_heading_wrapper {
  padding: 105px 0;
  background-image: url('../../../assets/Career/careerbg.png');
  background-repeat: no-repeat no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
}

#JobDetails .job_detais_heading {
  text-align: center;
}

#Apply_job_form .steps-content {
  width: 100%;
}

#Apply_job_form .ant-result-info {
  padding: 6px 32px;
}

#Apply_job_form .ant-result-icon svg {
  color: #7c7c7c;
}

#Apply_job_form .ant-btn-primary {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

@media screen and (min-width: 768px) {
  #Apply_job_form .highest-degree-row .ant-form-item {
    width: calc(100% - 8px) !important;
  }
}

#Apply_job_form .ant-steps-item-process .ant-steps-item-icon {
  border-color: var(--primary-color);
}

#JobDetails .about_our_company {
  margin: 40px 0;
}

#JobDetails .about_our_company p {
  text-align: justify;
  font-size: 18px;
}

#JobDetails .job_description .ant-form {
  background: #f8fafc;
  box-shadow: 0px 8px 30px rgb(0 0 0 / 15%);
  border-radius: 4px;
  padding: 15px 20px;
}

#JobDetails .job_description .ant-form .ant-form-item {
  margin-bottom: 13px;
}

#JobDetails .Service_Hero_container_email_form {
  padding: 5px 9px;
  border: 1.5px solid #969191;
  box-sizing: border-box;
  border-radius: 8px;
  display: block;
  background: #ffffff;
  max-width: 300px;
}

#JobDetails .Service_Hero_container_email_form .ant-btn {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  border-radius: 5px;
}
#JobDetails .Service_Hero_container_email_form .ant-input {
  border: none !important;
}

#JobDetails .Service_Hero_container_email_form span {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
