.ServiceTab_component {
  padding: 60px 0;
  background: #f0f9ff;
}

.ServiceTab_component .ServiceTab_heading {
  font-size: 24px;
  color: #ff8c47;
  font-weight: 600;
}

.ServiceTab_component .ServiceTab_wrapper {
  text-align: center;
}

.ServiceTab_component .ServiceTab_subheading {
  font-size: 32px;
  color: #014374;
  font-weight: 600;
  margin: 15px 0;
}

.ServiceTab_component .swiper {
  padding: 25px 0;
}

.ServiceTab_component .swiper-button-prev,
.ServiceTab_component .swiper-button-next {
  top: 2%;
}

.ServiceTab_component .swiper-button-disabled {
  display: none;
}

.ServiceTab_component .swiper-button-next {
  background: url('../../../../assets/Services/next_arrow.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.ServiceTab_component .swiper-button-next::after {
  display: none;
}

.ServiceTab_component .swiper-button-prev {
  background: url('../../../../assets//Services/prev_arrow.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.ServiceTab_component .swiper-button-prev::after {
  display: none;
}
