#Footer {
  background: #22292b;
  padding: 40px 0;
  z-index: 5;
  position: relative;
  /* margin-top: 300px; */
}

#Footer .footer_wrapper .footer_logo img {
  height: 55px;
  width: 130px;
}

#Footer .footer_wrapper .footer_heading {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  margin: 15px 0;
}

#Footer .footer_wrapper .footer_sub_heading {
  font-size: 18px;
  color: rgba(237, 235, 235, 0.946);
  margin: 7px 0;
}

#Footer .footer_wrapper .footer_sub_heading a {
  color: rgba(237, 235, 235, 0.946);
  transition: 0.25s;
}

#Footer .footer_wrapper .footer_sub_heading a:hover {
  color: var(--primary-color);
}

#Footer .footer_wrapper .footer_sub_heading a svg {
  margin-bottom: -4px;
}

#Footer .footer_wrapper .footer_facebook_icon a:hover {
  color: #4267b2;
}

#Footer .footer_wrapper .footer_instagram_icon a:hover {
  color: #bc2a8d;
}

#Footer .footer_wrapper .footer_linkedin_icon a:hover {
  color: #0e76a8;
}

#Footer .lets_connect_in_footer {
  padding: 50px 0;
  margin-top: -200px;
}
