#OurStandardPractices {
  z-index: 5;
  position: relative;
  padding: 50px 0;
  background: linear-gradient(180deg, #003266 0%, #001e3d 101.96%);
}

#OurStandardPractices .OurStandardPractices_wrapper .ant-row {
  margin: 35px 0;
}

#OurStandardPractices .OurStandardPractices_sub-heading {
  font-size: 18px;
  color: rgb(216, 216, 216);
  margin: 10px 0;
}

#OurStandardPractices .OurStandardPractices_heading {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}

#OurStandardPractices .OurStandardPractices_card {
  text-align: center;
  margin-top: 25px;
}

#OurStandardPractices .OurStandardPractices_card_img {
  height: 90px;
  width: 90px;
  padding: 20px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
}

#OurStandardPractices .OurStandardPractices_card_img img {
  height: 100%;
  width: 100%;
  filter: saturate(9);
}

#OurStandardPractices .OurStandardPractices_card_img:hover {
  transform: rotate(350deg);
  filter: saturate(1);
}

#OurStandardPractices .OurStandardPractices_card_text {
  font-size: 16px;
  margin-top: 10px;
  color: #fff;
}

/* .OurStandardPractices_sub-heading {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
} */

@media screen and (max-width: 768px) {
  .OurStandardPractices_sub-heading {
    max-width: 95%;
  }
}

.OurStandardPractices_wrapper {
  text-align: center;
}

.OurStandardPractices_wrapper .ant-divider {
  min-width: 20%;
  width: 20%;
  margin: 5px 0;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid var(--primary-color);
}
