.AdditionalTechnologyDescription
  .AdditionalTechnologyDescription_wrapper
  .service_rows_and_column_text
  h5 {
  text-align: justify;
}

.AdditionalTechnologyDescription_wrapper .service_rows_and_column_text h4 {
  font-size: 17px;
}
