#OurIndustries {
  z-index: 5;
  position: relative;
  padding: 40px 0 150px 0;
  background: linear-gradient(96.58deg, #f8fbfb 0%, #fcfdfd 100%);
}

#OurIndustries .OurIndustries_heading {
  font-size: 36px;
  font-weight: 600;
}

.OurIndustries_card_img {
  position: relative;
}

#OurIndustries .OurIndustries_card {
  text-align: center;
  cursor: pointer;
}

#OurIndustries .OurIndustries_card_img .OurIndustries_card_img_wrapper {
  height: 75px;
  width: 70px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#OurIndustries .OurIndustries_card_img .OurIndustries_card_text {
  margin-top: 10px;
  text-align: center;
}

#OurIndustries .OurIndustries_card_img .OurIndustries_card_img_wrapper img {
  /* height: 100%;
  margin-top: 30%; */

  height: 96px;
  padding: 2rem 1.25rem 0 1.25rem;
  width: 96px;
}

#OurIndustries .OurIndustries_card_img .OurIndustries_card_text {
  font-size: 16px;
  font-weight: 700;
}

#OurIndustries .OurIndustries_card_banner_img img {
  min-height: 350px;
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

#OurIndustries
  .OurIndustries_card_img:hover
  .OurIndustries_card_img_wrapper
  img {
  filter: saturate(8);
  transform: scale(1.02);
}

#OurIndustries .OurIndustries_card_banner_text {
  position: absolute;
  text-align: justify;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  bottom: 20px;
  padding: 20px 10px;
  text-shadow: 3px 4px 4px #000;
}

.OurIndustries_heading {
  text-align: center;
}

.OurIndustries_wrapper .ant-divider {
  min-width: 20%;
  width: 20%;
  margin: 5px 0;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid var(--primary-color);
}
