.individual_blog_card {
  margin: 10px;
  padding: 12px 12px 25px 12px;
  box-shadow: 0px 16px 20px rgb(0 0 0 / 16%);
  border-radius: 10px;
  transition: 0.4s;
}

.individual_blog_card :hover h3 {
  color: var(--primary-color);
}

.individual_blog_card h3 {
  transition: 0.3s;
}

.individual_blog_card .blog_card_img img {
  height: 232px;
  width: 100%;
}

.blog_card .ant-col {
  margin-top: 20px !important;
}

.card_date_category {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.card_date_category .card_category {
  margin-left: 20px;
  background: green;
  padding: 3px 10px;
  color: #fff;
  border-radius: 10px 0px 10px 0px;
  transition: 0.4s;
}

.individual_blog_card :hover .card_category {
  border-radius: 0px 10px 0px 10px;
}

.individual_blog_card a {
  color: var(--primary-color) !important;
}

.blog-sidebar-heading {
  color: var(--primary-color) !important;
}
