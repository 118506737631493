.RecruitmentProcess {
  padding: 40px 0 150px 0;
}

.RecruitmentProcess .RecruitmentProcess_heading {
  font-weight: 800;
  font-size: 36px;
  text-align: center;
}
.RecruitmentProcess .RecruitmentProcess_cards {
  margin-top: 30px;
}

.RecruitmentProcess .RecruitmentProcess_card {
  margin: 10px;
}

.RecruitmentProcess .RecruitmentProcess_card .RecruitmentProcess_card_icon img {
  width: 48px;
  height: 48px;
}
.RecruitmentProcess .RecruitmentProcess_card_wrapper {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 20px;
}

.RecruitmentProcess
  .RecruitmentProcess_card_wrapper
  .RecruitmentProcess_card_title {
  font-weight: 700;
  font-size: 24px;
  margin: 10px 0;
}

.RecruitmentProcess
  .RecruitmentProcess_card_wrapper
  .RecruitmentProcess_card_text {
  font-size: 18px;
  text-align: justify;
}
