.ContactPageHeaderImg {
  background: url('../../../../assets/ContactPage/Contactbg.jpg');
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
}

.ContactPageHeaderImg .contact_heading_text {
  padding: 150px 0;
  text-align: center;
}

.ContactPageHeaderImg .contact_heading_text h1,
.ContactPageHeaderImg .contact_heading_text p {
  color: #000;
}

.ContactPageHeaderImg .contact_heading_text h1 {
  font-size: 36px;
}

.ContactPageHeaderImg .contact_heading_text p {
  font-size: 21px;
}
