.TechnologyDescription {
  padding: 80px 0 80px 0;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 50px;
  width: 100%;
}

.TechnologyDescription .hero_container_sub-heading h5 {
  color: #fff;
  text-shadow: 3px 4px 4px #000;
}

.TechnologyDescription h1 {
  color: #fff !important;
}

.TechnologyDescription .hero_container_sub-heading ul li {
  color: #fff;
  font-size: 16px;
  text-shadow: 3px 4px 4px #000;
}

.tech-discription-in-tech-stack p {
  color: #fff;
  text-shadow: 3px 4px 4px #000;
}

@media screen and (min-width: 768px) {
  .TechnologyDescription .hero_container_sub-heading {
    background: #2e2b299e;
    padding: 10px;
  }
}

.TechnologyDescription .hero_container_sub-heading p {
  text-align: justify;
  font-size: 17px;
}
