.OurValues_career {
  padding: 60px 0;
}

.OurValues_career .OurValues_career_heading {
  font-weight: 800;
  font-size: 36px;
  text-align: center;
  margin-bottom: 25px;
}

.OurValues_career .OurValues_career_card_wrapper {
  background: #ffffff;
  box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 20px;
}

.OurValues_career .OurValues_career_card {
  margin: 15px;
}

.OurValues_career .OurValues_career_card_wrapper .OurValues_career_card_title {
  font-weight: 800;
  font-size: 26px;
  text-align: center;
  margin: 10px 0;
}

.OurValues_career .OurValues_career_card_wrapper .OurValues_career_card_info {
  font-size: 18px;
  text-align: justify;
}
