#Hero_container {
  background: url('../../../../assets/Services/Service_background.png')
    no-repeat center;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.hero_container_wrapper {
  padding: 80px 0;
}

@media screen and (max-width: 768px) {
  .hero_container_wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 12px;
  }
}

.hero_container_heading .ant-typography {
  word-wrap: break-word;
}

.hero-contact-btn {
  background: var(--primary-color);
  display: inline-block;
  color: #ffffff;
  border: 2px solid var(--primary-color);
  border-radius: 6px;
  padding: 10px 25px;
  font-size: 21px;
  margin-right: 3%;
  transition: 0.3s;
  font-weight: 650;
  white-space: nowrap;
}

.hero-contact-btn:hover {
  background: transparent;
  color: var(--primary-color);
}

.hero-our-work-btn {
  display: inline-block;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 6px;
  padding: 9px 24px;
  font-size: 21px;
  font-weight: 650;
  transition: 0.3s;
}

.hero-our-work-btn:hover {
  background: var(--primary-color);
  color: #ffffff;
}

.hero-contact-btn,
.hero-our-work-btn {
  margin-top: 15px;
}

#hero_svg svg {
  z-index: 50000;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 512px) {
  #hero_svg {
    margin-top: -20%;
  }
}

#hero_svg img {
  width: 100%;
}

h1.ant-typography,
.ant-typography h1 {
  font-size: 48px !important;
}
