@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}

* {
  font-family: 'Nunito', sans-serif !important;
}

:root {
  --primary-color: #ff7500;

  --secondary-color: #008aff;

  --active-links: #e4a1ef;

  --text-color-one: #1c1917;

  --text-color-two: #3b322b;

  --bg-color-one: #fff7f0;

  --bg-color-two: #ffdbbd;

  --footer-color: #1f282e;

  --antd-wave-shadow-color: #ff7500 !important;

  --primary-btn: linear-gradient(180deg, #ff9133 0%, #ff7500 100%);
}

/* container */

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1340px;
    max-width: 100%;
  }
}

/* Container ending */

/* Scroll Bar */

body::-webkit-scrollbar {
  width: 0.8em;
}

/* body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} */

body::-webkit-scrollbar-thumb {
  background-color: var(--bg-color-two);

  outline: 2px solid var(--bg-color-one);
}

/* --------------- */

/* ant Design Settings */
.ant-back-top {
  background: var(--primary-color);
  border-radius: 50%;
  color: #fff !important;
}

.ant-back-top svg {
  margin: 4px 0px 0px 4px;
}

.ant-affix {
  z-index: 999999999;
  background: #fff;
}

.ant-menu-submenu-selected {
  color: var(--primary-color) !important;
}

.ant-select {
  border-color: var(--primary-color);
}

::selection {
  color: #fff;
  background: var(--primary-color) !important;
}

.ant-select:not(.ant-select-disabled) .ant-select-selector {
  box-shadow: 0 0 0 2px rgba(255, 232, 24, 0.05) !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px rgba(255, 232, 24, 0.05) !important;
}

/* .ant-select:not(.ant-select-disabled) .ant-select-selector {
  box-shadow: 0 0 0 2px rgba(255, 232, 24, 0.05) !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px rgba(255, 232, 24, 0.05) !important;
} */

/* ENding ant Design Settings */

/* Navbar Desktop */
.gYpHDf {
  z-index: 9;
}

.dBeFnM {
  z-index: 9;
}

.hero-contact-btn {
  background: var(--primary-color);
  display: inline-block;
  color: #ffffff;
  border: 2px solid var(--primary-color);
  border-radius: 6px;
  padding: 10px 25px;
  font-size: 21px;
  margin-right: 3%;
  transition: 0.3s;
  font-weight: 650;
  white-space: nowrap;
}

.hero-contact-btn:hover {
  background: transparent;
  color: var(--primary-color);
}

.hide {
  display: none;
}

.text-centre {
  text-align: center;
}

/* .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--primary-color);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus {
  border-color: var(--primary-color);
}

textarea.ant-input {
  border-color: var(--primary-color);
}

.ant-form-item-control {
  border-color: var(--primary-color);
} */

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--primary-color);
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--primary-color);
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--primary-color);
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: var(--primary-color);
}
