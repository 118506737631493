.SearchJobs_career {
  padding: 40px 0;
}

.SearchJobs_career .SearchJobs_career_job_opening_card {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding: 20px;
}

.SearchJobs_career .SearchJobs_career_heding_and_available_jobs {
  display: flex;
  justify-content: space-between;
  margin: 13px 0;
}

.SearchJobs_career
  .SearchJobs_career_heding_and_available_jobs
  .SearchJobs_career_heding {
  font-weight: 700;
  font-size: 28px;
}

.SearchJobs_career
  .SearchJobs_career_heding_and_available_jobs
  .SearchJobs_career_available_jobs {
  font-weight: 18px;
}

.SearchJobs_career .search_jobs_available_jobs {
  margin: 40px 0;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SearchJobs_career .SearchJobs_career_filter_options {
  margin-top: 10px;
  text-align: left;
}

.SearchJobs_career .SearchJobs_career_filter_options .ant-col {
  margin: 10px 0;
}

.SearchJobs_career .hero_container_buttons {
  text-align: center;
}

.SearchJobs_career .hero_container_buttons .hero-contact-btn {
  padding: 2px 20px !important;
  font-size: 18px;
  font-weight: normal !important;
}

.search_jobs_available_jobs {
  margin: 40px 0 !important;
}

@media screen and (max-width: 576px) {
  .SearchJobs_career .SearchJobs_career_filter_options .ant-select {
    width: 100% !important;
  }
}
