#NAVBAR .logo img {
  height: 38px;
  width: 100.5px;
}
#NAVBAR {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

#NAVBAR .navbar-items {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

#NAVBAR .navbar-items ul {
  margin-bottom: 0 !important;
  float: left;
}
#NAVBAR .navbar-items .desktop-nav-links_item ul a {
  color: var(--text-color-one);
  transition: 0.3s;
}

#NAVBAR .navbar-items .desktop-nav-links_item ul a:hover {
  color: var(--primary-color);
}

#NAVBAR .navbar-items .desktop-nav-links_item ul li {
  list-style: none;
  font-size: 18px;
  display: inline-block;
  font-weight: 600;
}

#NAVBAR .navbar-items .desktop-nav-links_item ul li {
  margin: 0 15px;
}

@media only screen and (max-width: 992px) {
  #NAVBAR {
    display: none;
  }
}

.ant-dropdown {
  top: 90px !important;
}

.ant-dropdown-placement-bottomCenter {
  width: 20%;
}

.ant-menu {
  line-height: unset;
}

.navbar-dropdown-menu .service_list {
  padding: 12px 0px;
  background: #ff770007;
  /* border: 2px solid rgb(175, 173, 173); */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 3px solid var(--primary-color);
}

.arrow-up {
  width: 0;
  height: 0;
  margin-left: 35%;
  position: absolute;
  margin-top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid var(--primary-color);
}

.navbar-dropdown-menu ul li {
  line-height: 35px;
  font-weight: 500;
}

.navbar-dropdown-menu ul a:hover {
  background: #ebf5ff;
  display: block;
}

.navbar-dropdown-menu ul li svg {
  margin-bottom: -2px;
}

.navbar-dropdown-menu ul a {
  color: var(--text-color-one);
  transition: 0.2s;
}

.navbar-dropdown-menu ul a:hover {
  color: var(--primary-color);
}

.navbar_hero_container_button .hero-contact-btn {
  margin: 0;
  padding: 5px 10px !important;
  font-size: 17px !important;
  border-radius: 3px !important;
}
.ant-menu-vertical {
  border-radius: 6px;
}

.navbar-dropdown-menu ul {
  padding-inline-start: 0px !important;
}

.navbar-dropdown-menu ul li img {
  height: 20px;
  width: 20px;
}

/* #NAVBAR .navbar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
