.ServiceCard {
  margin-top: 15px;
}

.ServiceCard .ServiceCard_wrapper {
  background: #95eefe;
  /* border: 1px solid var(--primary-color); */
  box-sizing: border-box;
  transition: 0.2s;
  border-radius: 10px;
  padding: 18px 12px;
}

.ServiceCard .ServiceCard_wrapper:hover {
  box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 10%);
  filter: saturate(2);
  transform: scale(1.009);
}

.ServiceCard .ServiceCard_title {
  font-weight: 700;
  font-size: 28px;
  margin: 8px 0;

  line-height: 1.5em;
  height: 3em; /* height is 2x line-height, so two lines will display */
  overflow: hidden;
  /* padding: 0 60px; */
}

.ServiceCard .ServiceCard_img img {
  height: 130px;
  width: 130px;

  -webkit-animation: service_card_img_animation 1s infinite alternate;
  animation: service_card_img_animation 1s infinite alternate;
}

@-webkit-keyframes service_card_img_animation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes service_card_img_animation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.ServiceCard .ServiceCard_wrapper a {
  color: #014374;
}
